








import {Vue, Prop, Component} from 'vue-property-decorator'

@Component
export default class FilterSection extends Vue{
    @Prop({default:'mdi-filter'}) icon !: string;
    @Prop() header !:string;
}
