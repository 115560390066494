import client from './../apiService';

class ReportsService{
    async getReportDetailsFromSubscription(SubscriptionKey: string){
        const url = `Reports/GetReportDetails?SubscriptionKey=${SubscriptionKey}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    async saveSettings(settings: any){
        const url = `UserWiseCustomReport/SaveUserWiseCustomReportRequest`;
        return client.post(url, settings, 
        {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    async requestReportDownload(request: any){
        const url = `Reports/RequestToDownload`;
        return client.post(url, request, 
        {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}

export default new ReportsService();