import { render, staticRenderFns } from "./CircularLoader.vue?vue&type=template&id=5be53c28&scoped=true&"
import script from "./CircularLoader.vue?vue&type=script&lang=ts&"
export * from "./CircularLoader.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5be53c28",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VProgressCircular})
