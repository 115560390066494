import {ReportType} from './reports'
export {ReportType}
export enum InputType{
    checkbox = 0,
}
export enum DateFiltersType
{
    None = 0,
    DateRange = 1,
    SingleDate = 2,
    SingleMonth = 3,
    MonthRange = 4,
    JourneyCalendar = 5,
    Quarter = 6
}
export enum IndividualFilterType{
    EmployeeHierarchyMultiSelect = 1,
    PositionHierarchyMultiSelect = 2,
    ProductCategoryMultiSelect = 3,
    PSODShowDataForFilter = 4,
    SaleTypeVanSale = 5,
}
export enum UserRole {
    GlobalAdmin = 0,
    AccountManager = 3,
    ChannelPartner = 6,
    CompanyAdmin = 10,
    CompanyExecutive = 12,
    GlobalSalesManager = 20,
    NationalSalesManager = 30,
    ZonalSalesManager = 40,
    RegionalAdmin = 45,
    RegionalSalesManager = 50,
    AreaSalesManager = 60,
    Distributor = 70,
    ClientEmployee = 80,
    Unknown = 1000,
}
export enum PositionLevel{
    Level1 = 60,
    Level2 = 50,
    Level3 = 40,
    Level4 = 30,
    Level5 = 20,
    Level6 = 10,
    Level7 = 9,
    Level8 = 8,
    Unknown = 1000,
}
export enum ProductHierarchy{
    NoFilter = 0,
    Division = 10,
    PrimaryCategory = 20,
    SecondaryCategory = 30,
    Product = 40
}
export enum GeographyLevel
{
    Beat = 10,
    Territory = 20,
    Region = 30,
    Level4 = 40,
    Level5 = 50,
    Level6 = 60,
    Level7 = 70
}
export enum GeographicalHierarchy
{
    nofilter = 0,
    Level7 = 1,
    Level6 = 2,
    Level5 = 3,
    Zone = 10,
    Region = 20,
    Territory = 30,
    Beat = 40,
    Outlet = 50

}
export enum EmployeeGeographicalHierarchy
{
    Zone = 10,
    Region = 20,
    AreaSalesManager = 30,
    ClientEmployee = 40
}
export enum DistributorGeographicalHierarchy
{
    Zone = 10,
    Region = 20,
    Distributor = 30
}
export  enum ReportRequestStatus
{
    Unknown = 0,
    Requested = 1,
    Executed = 2,
    Cancelled = 3,
    ErrorDuringExecution = 4,
    InvalidRequest = 5,
    Executing = 6
}
export enum DateRangePreset
{
    Today = 5,
    Yesterday = 10,
    Last7Days = 20,
    Last30Days = 30,
    Last3Months = 40,
    MTD = 50,
    Unknown = 0,
}
export enum NotificationType{
    None = 0,
    Feed = 1,
    Banner = 2
}
export enum StockistType{
    Unknown = 0,
    Stockist = 1,
    SuperStockist = 2,
    SubStockist = 3
}
