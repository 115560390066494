
























import {Component, Vue, Prop, Emit} from 'vue-property-decorator'
import TextButton from '@/components/common/Buttons/TextButton.vue'
@Component({
    components: {
        TextButton
    }
})
export default class ActionablePopup extends Vue {
    @Prop({default: false, required: true}) readonly popupDialog!: boolean
    @Prop() readonly title !: string
    @Prop() readonly subtitle !: string
    @Prop() readonly text !: string
    @Prop({default: true}) readonly persistent !: boolean
    @Prop({default: 'Confirm'}) readonly confirmBtnText !: string

    
    public confirm(){
        this.$emit('confirm');
    }
}
