
















import Vue from 'vue'
import {Prop, Watch, Component} from 'vue-property-decorator'
import {InputType} from '@/interfaces/Enums'
import {OtherReportPreferences} from '@/interfaces/reports'

@Component({
    components:{
    }
})
export default class ReportPreferences extends Vue{
    @Prop({default: []}) preferences !: Array<OtherReportPreferences>

    private selectedPreferences: any = {};
    private InputTypeEnum = InputType;

    public getSelectedPreferences(): any {
        return this.selectedPreferences ? this.selectedPreferences : {};
    }

    @Watch('preferences')
    onPreferencesChanged(newValue: Array<OtherReportPreferences>, oldValue: Array<OtherReportPreferences>){
        if(newValue){
            this.selectedPreferences = {};
            newValue.forEach((preference: OtherReportPreferences) => {
                this.selectedPreferences[preference.name] = preference.value;
            });
        }
    }

    created() {
        this.preferences.forEach(preference => {
            this.selectedPreferences[preference.name] = preference.value;
        });
    }
}
