import { PositionLevel } from '@/interfaces/Enums';
import client from './../apiService';

class PositionCodeService{
    async getPositionCodes(searchTerm: string, page: number){
        const url = `PositionCode/GetPositionCodes?searchTerm=${searchTerm}&page=${page}`;
        return await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    async GetVacantPositionCodes(searchTerm: string, page: number){
        const url = `PositionCode/GetVacantPositionCodesInBatch?searchTerm=${searchTerm}&page=${page}`;
        return await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getPositionsBySearch(searchString: string,counter: number){
        const url = `PositionCode/GetPositionsBySearch?searchString=${searchString}&counter=${counter}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getReportingToPositions(positionLevel: string){
        const url = `PositionCode/GetReportingToPositions?positionCodeLevel=${positionLevel}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    savePositionCode(positionCode: object){
        const url = `PositionCode/SavePositionCode`;
        return client.post(url, positionCode, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    updatePositionCode(positionCode: object){
        const url = `PositionCode/UpdatePositionCode`;
        return client.post(url, positionCode, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    attachBeats(positionId: number, beatIds: Array<number>){
        const url = `PositionCode/AttachBeats?positionCodeId=${positionId}`;
        return client.post(url, beatIds, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async getHighestPositionLevel(){
        const url = `PositionCode/GetHighestPositionLevelForCompany`;
        return await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    attachDistributor(positionId: number, DistributorIds: Array<number>){
        const url = `PositionCode/AttachDistributors?positionCodeId=${positionId}`;
        return client.post(url, DistributorIds, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    attachOutlet(positionId: number, outletIds: Array<number>){
        const url = `PositionCode/AttachOutlet?positionCodeId=${positionId}`;
        return client.post(url, outletIds, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    deactivatePosition(positionId: number){
        const url = `PositionCode/DeactivatePosition?positionId=${positionId}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    activatePosition(positionId: number){
        const url = `PositionCode/ActivatePosition?positionId=${positionId}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async getInactivePositionCodes(searchTerm: string, page: number){
        const url = `PositionCode/GetInactivePositionCodes?searchTerm=${searchTerm}&page=${page}`;
        return await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getPositionsOfLevel(level: PositionLevel){
        const url = `PositionCode/GetAllPositionsOfLevel?level=`+level;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}

export default new PositionCodeService();