















































































    import Vue from 'vue'
    import {Component} from 'vue-property-decorator'

    import {ReportDetails, datePresets, OtherReportPreferences} from '@/interfaces/reports'
    import {DateFiltersType, DateRangePreset, IndividualFilterType, PositionLevel, ReportRequestStatus, ReportType, UserRole} from '@/interfaces/Enums'
    
    import ReportService from '@/services/ReportsService'
    
    import Navbar from '@/components/common/Navbar.vue'
    import CircularLoader from '@/components/common/Loading/CircularLoader.vue'
    import Snackbar from '@/components/common/Snackbar.vue'
    import DateFilter from '@/components/common/Inputs/DateFilter.vue'
    import SelectFilters from '@/components/reports/SelectFilters.vue'
    import ReportPreferences from '@/components/reports/ReportPreferences.vue'
    import SideDrawer from '@/components/common/SideDrawer.vue'
    import FilterSection from '@/components/common/Headers/FilterSection.vue'
    import SingleActionPopUp from '@/components/common/Popup/SingleActionPopUp.vue'
    import CompanySettingServics from '@/services/CompanySettingServics'
    import { oldPortalUrl } from '@/envConfig'


    @Component({
            components: {
                Navbar,
                Snackbar,
                CircularLoader,
                DateFilter,
                SelectFilters,
                SideDrawer,
                FilterSection,
                SingleActionPopUp,
                ReportPreferences
            }
        })
    export default class Download extends Vue{
        private myRequestsURL : string = `${oldPortalUrl}DataVisualization/DumpReport`;
        private snackbarMessage: string = "";
        private snackbar: boolean = false;
        private snackbarTimeout: number = 5000;
        private snackbarColor: string = "";
        private dateToday: Date = new Date();
        private sideDrawer: boolean = false;
        private showOnlyDataColumns: boolean = false;
        private reportColumns: Map<string,Array<any>> = new Map();
        private selectedColumns: Array<any> = []
        private confirmationPopup: boolean = false;
        private selectFiltersLoaded: boolean = false;
        private confirmationPopupText: string = "</br></br></br>"
        private confirmationPopupTextOriginal: string = "</br></br></br>"
        private confirmBtnText: string = `Download When Processed`;
        private popUpTimer: number = 0;
        private reportLoaded: boolean = false;
        private nomenclatureDict : any = {};
        private requestId : number = -1;
        private framesource : boolean = false;
        private $reportsHub : any; //NOTE: this declaration is just to remove error from typescript linter
        private reportDetails: ReportDetails = {
            reportId:0,
            customReportId:0,
            maxColumnsSelectable: 30,
            name: "",
            canCustomize : true,
            dateFilterType : DateFiltersType.None,
            datePresets : [],
            maxDaysAllowed: 31,
            filters : [],
            userDetails : {
                highestRole : UserRole.Unknown,
                userRole : UserRole.Unknown,
                id : 0,
                newId : 0,
                userHighestPosition: PositionLevel.Unknown,
                highestPosition: PositionLevel.Unknown,
                positionIds : [],
                usesPositionCodes: false,
            },
            columns:[],
            categories: [],
            displayCategories: [],
            otherPreferences: [],
        };
        get loading():boolean{
            return !this.selectFiltersLoaded || this.reportDetails.reportId == 0
        }

        private subscriptionKey: string = this.$route.query.subscriptionKey.toString();

        private togglePopUp(value: boolean){
            this.confirmationPopup = value;
            if(!value){
                this.confirmationPopupText = this.confirmationPopupTextOriginal;
            }
        }
        private confirmPopUpAction() {
            if(this.popUpTimer == 0){
                this.togglePopUp(false);
                this.snackbarTimeout = 9999999999;
                this.showSnackbarInfoMessage("Do not close tab. Report is processing")
            }
        }
        private applySettings() {
            console.log("kd");
            let reportPreferences = this.getRefAsAny('reportPreferences');
            let settings = {
                customReportId: this.reportDetails.customReportId,
                reportFilters: this.selectedColumns.map(id => {
                    let c = this.reportDetails.columns.find(c => c.id == id);
                    if(c != undefined){
                        return{
                            categoryName: c.categoryName,
                            reportColumnName: c.reportColumnName
                        }
                    }else{
                        return {
                            categoryName: "",
                            reportColumnName: ""
                        }
                    }
                }),
                otherPreferences: reportPreferences.getSelectedPreferences()            
            }
            ReportService.saveSettings(settings).then(res => {
                this.showSnackbarSuccessMessage("Columns Preference Updated Successfully");
                this.closeSnackbar(true);
                this.toggleSideDrawer(false);
            }).catch(err => {
                this.showSnackbarErrorMessage();
                this.closeSnackbar(true);
                this.selectedColumns = this.reportDetails.columns.filter(c => c.isSelected || c.isStandardColumn).map(c => c.id);
            });
        }
        private toggleSideDrawer(value: boolean) {
            this.sideDrawer = value;
        }
        private customizeReport() {
            this.toggleSideDrawer(true);
        }
        public async getReportDetails(): Promise<void> {
            ReportService.getReportDetailsFromSubscription(this.subscriptionKey).then(res => {
                this.reportLoaded = true;
                this.reportDetails = res.data;
                this.getColumns()
            }).catch(e => {
                this.showSnackbarErrorMessage()
            })
        }
        public async getNomenclature(): Promise<void> {
            CompanySettingServics.getHierarchyNomenclature().then(res => {
                this.nomenclatureDict = res.data
            }).catch(err => {
                this.showSnackbarErrorMessage();
            });
        }
        private getColumns() {
            let columns = this.reportDetails.columns;
            let categories = this.reportDetails.categories;
            categories.forEach(category => {
                this.reportColumns.set(category, columns.filter(c => c.categoryName == category))
            });
            this.selectedColumns = columns.filter(c => c.isSelected || c.isStandardColumn).map(c => c.id)
        }
        private getRefAsAny(refKey: string){
            return (this.$refs[refKey] as any)
        }
        private showSnackbarErrorMessage() {
            this.snackbarColor = "red";
            this.snackbar = true;
            this.snackbarMessage = window.navigator.onLine ? `Some Error occured` : `Cannot reach server at the moment`;
        }
        private showSnackbarSuccessMessage(msg : string) {
            this.snackbarColor = "success";
            this.snackbar = true;
            this.snackbarMessage = msg;
        }
        private showSnackbarInfoMessage(msg : string) {
            this.snackbarColor = "yellow";
            this.snackbar = true;
            this.snackbarMessage = msg;
        }
        public closeSnackbar(value: boolean): void{
            this.snackbarTimeout = 5000;
            this.snackbar = value;
        }
        private async startPopUpTimer() : Promise<void> {
            if(this.popUpTimer > 0){
                setTimeout(_ => {
                    this.popUpTimer -= 1;
                    this.confirmBtnText = `Download When Processed (${this.popUpTimer})`;
                    this.startPopUpTimer();
                }, 1000)
            }
            else{
                this.confirmBtnText = `Download When Processed`;
            }
        }
        public async download(){
            this.$reportsHub.stopReportsHub();
            let dateFilters = this.getRefAsAny('dateFilter').getDateFilters();
            let selectFilters = this.getRefAsAny('selectFilters').getSelectFilters();
            let reportrequest = {
                ...dateFilters,
                ...selectFilters,
                subscriptionKey : this.subscriptionKey
            }
            this.togglePopUp(true);
            ReportService.requestReportDownload(reportrequest).then(res => {
                this.confirmationPopupText = this.confirmationPopupText.replace('</br>','<p class=\"mb-0\">Queued</p>');
                this.popUpTimer = 10;
                this.startPopUpTimer();
                this.requestId = res.data.id;
                this.$reportsHub.startReportsHub(this.requestId);
                this.$reportsHub.$on(`${this.requestId}-status-update`, this.listenForUpdates)
            })
        }
        private listenForUpdates(update: any) {
            console.log(update);
            if(update.status == ReportRequestStatus.Executing){
                this.confirmationPopupText = this.confirmationPopupText.replace('</br>','<p class=\"mb-0\">Executing</p>');
            }
            if(update.status == ReportRequestStatus.Executed){
                this.confirmationPopupText = this.confirmationPopupText.replace('</br>','<p class=\"mb-0\">Downloading</p>');
                if(this.framesource)
                    window.parent.postMessage({downloadLink: update.downloadLink, isFromNewDashboard: true},"*");
                else{
                    window.location = update.downloadLink;
                }
                setTimeout(_ => {
                    this.togglePopUp(false);
                }, 2000)
                this.$reportsHub.$off(`${this.requestId}-status-update`, this.listenForUpdates)
                this.$reportsHub.stopReportsHub();
            }
        }
        private selectColumn(columnId : number) {
            if(this.selectedColumns.length > this.reportDetails.maxColumnsSelectable){
                this.selectedColumns = this.selectedColumns.filter(id => id != columnId)
            }
        }
        mounted(){
            this.getNomenclature();
            this.getReportDetails();
            const url = new URL(document.location.href)
			if(url.searchParams.get('source') == "iframe"){
				this.framesource = true;
			}
        }
    }
