






















































































import Vue from 'vue'
import {Prop, Watch, Component} from 'vue-property-decorator'
import {IndividualFilterType, PositionLevel, ProductHierarchy, UserRole} from '@/interfaces/Enums'
import {UserDetails} from '@/interfaces/reports'
import ListItemContent from '@/components/common/List/ListItemContent.vue'
import MultiSelect from '@/components/common/Inputs/MultiSelect.vue'
import FilterSection from '@/components/common/Headers/FilterSection.vue'

import EmployeeService from '@/services/EmployeeService'
import ProductService from '@/services/ProductService'
import PositionCodeService from '@/services/PositionCodeService'
import { AxiosResponse } from 'axios'

@Component({
    components:{
        MultiSelect,
        FilterSection,
        ListItemContent
    }
})
export default class SelectFilters extends Vue{
    @Prop({default: null}) userDetails !: UserDetails
    @Prop() filters !: Array<IndividualFilterType>
    @Prop({default:{}}) nomenclatureDict !: any
    @Prop({default:false}) loadFilterdata !: boolean

    @Watch('loadFilterdata')
    loadData(value: boolean){
        if(value){
            Promise.all(this.loadFilters()).then(_ => {
                this.$emit('select-filters-loaded');
            });
        }
    }

    private getRefAsAny(refKey: string){
        return (this.$refs[refKey] as any)
    }

    //#region Users
    private gsms  : Array<any> = []
    private nsms_ : Array<any> = []
    private zsms_ : Array<any> = []
    private rsms_ : Array<any> = []
    private asms_ : Array<any> = []
    private esms_ : Array<any> = []

    private selectedGSM : Array<any> = [];
    private selectedNSM : Array<any> = [];
    private selectedZSM : Array<any> = [];
    private selectedRSM : Array<any> = [];
    private selectedASM : Array<any> = [];
    private selectedESM : Array<any> = [];

    get nsms() : Array<any> {
        if(this.userDetails.userRole == UserRole.GlobalSalesManager){
            return this.nsms_.filter(n => n.parentId == this.userDetails.newId);
        }
        return this.nsms_.filter(n => !this.showGsm || this.selectedGSM.some(g => n.parentId == g.id));
    }
    get zsms() : Array<any> {
        if(this.userDetails.userRole == UserRole.NationalSalesManager){
            return this.zsms_.filter(z => z.parentId == this.userDetails.newId)
        }
        return this.zsms_.filter(n => !this.showNsm || this.selectedNSM.some(g => n.parentId == g.id));
    }
    get rsms() : Array<any> {
        if(this.userDetails.userRole == UserRole.ZonalSalesManager){
            return this.rsms_.filter(r => r.parentId == this.userDetails.newId)
        }
        return this.rsms_.filter(n => !this.showZsm || this.selectedZSM.some(g => n.parentId == g.id));
    }
    get asms() : Array<any> {
        if(this.userDetails.userRole == UserRole.RegionalSalesManager){
            return this.asms_.filter(a => a.parentId == this.userDetails.newId)
        }
        return this.asms_.filter(n => !this.showRsm || this.selectedRSM.some(g => n.parentId == g.id));
    }
    get esms() : Array<any> {
        if(this.userDetails.userRole == UserRole.AreaSalesManager){
            return this.esms_.filter(e => e.parentId == this.userDetails.newId);
        }
        return this.esms_.filter(n => !this.showAsm || this.selectedASM.some(g => n.parentId == g.id));
    }

    get showEmployeeFilters() : boolean {
        return this.filters.some(f => f == IndividualFilterType.EmployeeHierarchyMultiSelect);
    }
    get showGsm() : boolean {
        return this.userDetails.highestRole <= UserRole.GlobalSalesManager && this.userDetails.userRole < UserRole.GlobalSalesManager
    }
    get showNsm() : boolean {
        return this.userDetails.highestRole <= UserRole.NationalSalesManager && this.userDetails.userRole < UserRole.NationalSalesManager
    }
    get showZsm() : boolean {
        return this.userDetails.highestRole <= UserRole.ZonalSalesManager && this.userDetails.userRole < UserRole.ZonalSalesManager
    }
    get showRsm() : boolean {
        return this.userDetails.userRole < UserRole.RegionalSalesManager
    }
    get showAsm() : boolean {
        return this.userDetails.userRole < UserRole.AreaSalesManager
    }

    private gsmChange(sGsm : Array<any>){
        this.selectedGSM = sGsm;
        this.getRefAsAny("nsmSelect").clickItem(this.nsms);
    }
    private nsmChange(sGsm : Array<any>){
        this.selectedNSM = sGsm;
        this.getRefAsAny("zsmSelect").clickItem(this.zsms);
    }
    private zsmChange(sGsm : Array<any>){
        this.selectedZSM = sGsm;
        this.getRefAsAny("rsmSelect").clickItem(this.rsms);
    }
    private rsmChange(sGsm : Array<any>){
        this.selectedRSM = sGsm;
        this.getRefAsAny("asmSelect").clickItem(this.asms);
    }
    private asmChange(sGsm : Array<any>){
        this.selectedASM = sGsm;
        this.getRefAsAny("esmSelect").clickItem(this.esms);
    }
    private esmChange(sGsm : Array<any>){
        this.selectedESM = sGsm;
    }
    private async loadEmployeeFilters() : Promise<void> {
        let allPromises : Array<Promise<AxiosResponse<any>>> = [];
        allPromises.push(EmployeeService.getEmployeesOfRole(UserRole.GlobalSalesManager));
        allPromises.push(EmployeeService.getEmployeesOfRole(UserRole.NationalSalesManager));
        allPromises.push(EmployeeService.getEmployeesOfRole(UserRole.ZonalSalesManager));
        allPromises.push(EmployeeService.getEmployeesOfRole(UserRole.RegionalSalesManager));
        allPromises.push(EmployeeService.getEmployeesOfRole(UserRole.AreaSalesManager));
        allPromises.push(EmployeeService.getEmployeesOfRole(UserRole.ClientEmployee));
        Promise.all(allPromises).then(allRepsonses => {
            this.gsms  = allRepsonses[0].data;
            this.nsms_ = allRepsonses[1].data;
            this.zsms_ = allRepsonses[2].data;
            this.rsms_ = allRepsonses[3].data;
            this.asms_ = allRepsonses[4].data;
            this.esms_ = allRepsonses[5].data;

            setTimeout(_ => {
                if(this.showGsm) this.getRefAsAny("gsmSelect").clickItem(this.gsms);
                if(this.showNsm) this.getRefAsAny("nsmSelect").clickItem(this.nsms);
                if(this.showZsm) this.getRefAsAny("zsmSelect").clickItem(this.zsms);
                if(this.showRsm) this.getRefAsAny("rsmSelect").clickItem(this.rsms);
                if(this.showAsm) this.getRefAsAny("asmSelect").clickItem(this.asms);
                this.getRefAsAny("esmSelect").clickItem(this.esms);
            }, 3000);
        }).catch(errors => {
            this.$emit('errorLoading', errors);
        });
    }
    private getLowestRoleSelectedUserIds(): any {
        let ids : Array<number> = [];
        let userRole !: UserRole;
        if(this.selectedESM.length > 0 && this.selectedESM.length < this.esms.length){
           ids = this.selectedESM.map(e => e.id);
           userRole = UserRole.ClientEmployee;
        }
        else if(this.selectedASM.length > 0 && this.selectedASM.length < this.asms.length && this.showAsm){
            ids = this.selectedASM.map(e => e.id)
            userRole = UserRole.AreaSalesManager;
        }
        else if(this.selectedRSM.length > 0 && this.selectedRSM.length < this.rsms.length && this.showRsm){
            ids = this.selectedRSM.map(e => e.id);
            userRole = UserRole.RegionalSalesManager;
        }
        else if(this.selectedZSM.length > 0 && this.selectedZSM.length < this.zsms.length && this.showZsm){
            ids = this.selectedZSM.map(e => e.id);
            userRole = UserRole.ZonalSalesManager;
        }
        else if(this.selectedNSM.length > 0 && this.selectedNSM.length < this.nsms.length && this.showNsm){
            ids = this.selectedNSM.map(e => e.id);
            userRole = UserRole.NationalSalesManager;
        }
        else if(this.selectedGSM.length > 0 && this.selectedGSM.length < this.gsms.length && this.showGsm){
            ids = this.selectedGSM.map(e => e.id);
            userRole = UserRole.GlobalSalesManager;
        }
        else{
            ids = [this.userDetails.newId];
            userRole = this.userDetails.userRole;
        }
        return {
            ids: ids,
            userRole: userRole
        };
    }
    //#endregion

    //#region Positions    
    private l8positions  : Array<any> = []
    private l7positions_  : Array<any> = []
    private l6positions_  : Array<any> = []
    private l5positions_ : Array<any> = []
    private l4positions_ : Array<any> = []
    private l3positions_ : Array<any> = []
    private l2positions_ : Array<any> = []
    private l1positions_ : Array<any> = []

    private selectedL8 : Array<any> = [];
    private selectedL7 : Array<any> = [];
    private selectedL6 : Array<any> = [];
    private selectedL5 : Array<any> = [];
    private selectedL4 : Array<any> = [];
    private selectedL3 : Array<any> = [];
    private selectedL2 : Array<any> = [];
    private selectedL1 : Array<any> = [];

    get l7positions() : Array<any>{
        if(this.userDetails.userHighestPosition == PositionLevel.Level8){
            return this.l7positions_.filter(p => this.userDetails.positionIds.some(g => p.parentId == g))
        }
        return this.l7positions_.filter(p => !this.showL8 || this.selectedL8.some(g => p.parentId == g.id))
    }
    get l6positions() : Array<any>{
        if(this.userDetails.userHighestPosition == PositionLevel.Level7){
            return this.l6positions_.filter(p => this.userDetails.positionIds.some(g => p.parentId == g))
        }
        return this.l6positions_.filter(p => !this.showL7 || this.selectedL7.some(g => p.parentId == g.id))
    }
    get l5positions() : Array<any>{
        if(this.userDetails.userHighestPosition == PositionLevel.Level6){
            return this.l5positions_.filter(p => this.userDetails.positionIds.some(g => p.parentId == g))
        }
        return this.l5positions_.filter(p => !this.showL6 || this.selectedL6.some(g => p.parentId == g.id))
    }
    get l4positions() : Array<any>{
        if(this.userDetails.userHighestPosition == PositionLevel.Level5){
            return this.l4positions_.filter(p => this.userDetails.positionIds.some(g => p.parentId == g))
        }
        return this.l4positions_.filter(p => !this.showL5 || this.selectedL5.some(g => p.parentId == g.id))
    }
    get l3positions() : Array<any>{
        if(this.userDetails.userHighestPosition == PositionLevel.Level4){
            return this.l3positions_.filter(p => this.userDetails.positionIds.some(g => p.parentId == g))
        }
        return this.l3positions_.filter(p => !this.showL4 || this.selectedL4.some(g => p.parentId == g.id))
    }
    get l2positions() : Array<any>{
        if(this.userDetails.userHighestPosition == PositionLevel.Level3){
            return this.l2positions_.filter(p => this.userDetails.positionIds.some(g => p.parentId == g))
        }
        return this.l2positions_.filter(p => !this.showL3 || this.selectedL3.some(g => p.parentId == g.id))
    }
    get l1positions() : Array<any>{
        if(this.userDetails.userHighestPosition == PositionLevel.Level2){
            return this.l1positions_.filter(p => this.userDetails.positionIds.some(g => p.parentId == g))
        }
        return this.l1positions_.filter(p => !this.showL2 || this.selectedL2.some(g => p.parentId == g.id))
    }

    get showPositionFilters() : boolean {
        return this.filters.some(f => f == IndividualFilterType.PositionHierarchyMultiSelect) && this.userDetails.usesPositionCodes;
    }
    get showL8() : boolean {
        return this.userDetails.highestPosition <= PositionLevel.Level8 && this.userDetails.userRole < UserRole.GlobalSalesManager;
    }
    get showL7() : boolean {
        return this.userDetails.highestPosition <= PositionLevel.Level7 && (this.userDetails.userHighestPosition < PositionLevel.Level7 || this.userDetails.userRole < UserRole.GlobalSalesManager);
    }
    get showL6() : boolean {
        return this.userDetails.highestPosition <= PositionLevel.Level6 && (this.userDetails.userHighestPosition < PositionLevel.Level6 || this.userDetails.userRole < UserRole.GlobalSalesManager);
    }
    get showL5() : boolean {
        return this.userDetails.highestPosition <= PositionLevel.Level5 && (this.userDetails.userHighestPosition < PositionLevel.Level5 || this.userDetails.userRole < UserRole.GlobalSalesManager);
    }
    get showL4() : boolean {
        return this.userDetails.userHighestPosition < PositionLevel.Level4 || this.userDetails.userRole < UserRole.GlobalSalesManager;
    }
    get showL3() : boolean {
        return this.userDetails.userHighestPosition < PositionLevel.Level3 || this.userDetails.userRole < UserRole.GlobalSalesManager;
    }
    get showL2() : boolean {
        return this.userDetails.userHighestPosition < PositionLevel.Level2 || this.userDetails.userRole < UserRole.GlobalSalesManager;
    }
    private l8Change(spos : Array<any>){
        this.selectedL8 = spos;
        this.getRefAsAny("l7Select").clickItem(this.l7positions);
    }
    private l7Change(spos : Array<any>){
        this.selectedL7 = spos;
        this.getRefAsAny("l6Select").clickItem(this.l6positions);
    }
    private l6Change(spos : Array<any>){
        this.selectedL6 = spos;
        this.getRefAsAny("l5Select").clickItem(this.l5positions);
    }
    private l5Change(spos : Array<any>){
        this.selectedL5 = spos;
        this.getRefAsAny("l4Select").clickItem(this.l4positions);
    }
    private l4Change(spos : Array<any>){
        this.selectedL4 = spos;
        this.getRefAsAny("l3Select").clickItem(this.l3positions);
    }
    private l3Change(spos : Array<any>){
        this.selectedL3 = spos;
        this.getRefAsAny("l2Select").clickItem(this.l2positions);
    }
    private l2Change(spos : Array<any>){
        this.selectedL2 = spos;
        this.getRefAsAny("l1Select").clickItem(this.l1positions);
    }
    private l1Change(spos : Array<any>){
        this.selectedL1 = spos;
    }

    private async loadPositionFilters() : Promise<void> {
        let positionPromises : Array<Promise<AxiosResponse<any>>> = [];
        positionPromises.push(PositionCodeService.getPositionsOfLevel(PositionLevel.Level8));
        positionPromises.push(PositionCodeService.getPositionsOfLevel(PositionLevel.Level7));
        positionPromises.push(PositionCodeService.getPositionsOfLevel(PositionLevel.Level6));
        positionPromises.push(PositionCodeService.getPositionsOfLevel(PositionLevel.Level5));
        positionPromises.push(PositionCodeService.getPositionsOfLevel(PositionLevel.Level4));
        positionPromises.push(PositionCodeService.getPositionsOfLevel(PositionLevel.Level3));
        positionPromises.push(PositionCodeService.getPositionsOfLevel(PositionLevel.Level2));
        positionPromises.push(PositionCodeService.getPositionsOfLevel(PositionLevel.Level1));
        Promise.all(positionPromises).then(allRepsonses => {
            this.l8positions  = allRepsonses[0].data;
            this.l7positions_ = allRepsonses[1].data;
            this.l6positions_ = allRepsonses[2].data;
            this.l5positions_ = allRepsonses[3].data;
            this.l4positions_ = allRepsonses[4].data;
            this.l3positions_ = allRepsonses[5].data;
            this.l2positions_ = allRepsonses[6].data;
            this.l1positions_ = allRepsonses[7].data;

            setTimeout(_ => {
                if(this.showL8) this.getRefAsAny("l8Select").clickItem(this.l8positions);
                if(this.showL7) this.getRefAsAny("l7Select").clickItem(this.l7positions);
                if(this.showL6) this.getRefAsAny("l6Select").clickItem(this.l6positions);
                if(this.showL5) this.getRefAsAny("l5Select").clickItem(this.l5positions);
                if(this.showL4) this.getRefAsAny("l4Select").clickItem(this.l4positions);
                if(this.showL3) this.getRefAsAny("l3Select").clickItem(this.l3positions);
                if(this.showL2) this.getRefAsAny("l2Select").clickItem(this.l2positions);
                this.getRefAsAny("l1Select").clickItem(this.l1positions);
            }, 3000);
        }).catch(errors => {
            this.$emit('errorLoading', errors);
        });
    }
    private getLowestLevelSelectedPositionIds(): any {
        let ids : Array<number> = [];
        let PositionCodeLevel !: PositionLevel;
        if(this.selectedL1.length > 0 && this.selectedL1.length < this.l1positions.length){
            ids = this.selectedL1.map(e => e.id);
            PositionCodeLevel = PositionLevel.Level1;
        }
        else if(this.selectedL2.length > 0 && this.selectedL2.length < this.l2positions.length && this.showL2){
            ids = this.selectedL2.map(e => e.id);
            PositionCodeLevel = PositionLevel.Level2;
        }
        else if(this.selectedL3.length > 0 && this.selectedL3.length < this.l3positions.length && this.showL3){
            ids = this.selectedL3.map(e => e.id);
            PositionCodeLevel = PositionLevel.Level3;
        }
        else if(this.selectedL4.length > 0 && this.selectedL4.length < this.l4positions.length && this.showL4){
            ids = this.selectedL4.map(e => e.id);
            PositionCodeLevel = PositionLevel.Level4;
        }
        else if(this.selectedL5.length > 0 && this.selectedL5.length < this.l5positions.length && this.showL5){
            ids = this.selectedL5.map(e => e.id);
            PositionCodeLevel = PositionLevel.Level5;
        }
        else if(this.selectedL6.length > 0 && this.selectedL6.length < this.l6positions.length && this.showL6){
            ids = this.selectedL6.map(e => e.id);
            PositionCodeLevel = PositionLevel.Level6;
        }
        else if(this.selectedL7.length > 0 && this.selectedL7.length < this.l7positions.length && this.showL7){
            ids = this.selectedL7.map(e => e.id);
            PositionCodeLevel = PositionLevel.Level7;
        }
        else if(this.selectedL8.length > 0 && this.selectedL8.length < this.l8positions.length && this.showL8){
            ids = this.selectedL8.map(e => e.id);
            PositionCodeLevel = PositionLevel.Level8;
        }
        else{
            ids = this.userDetails.positionIds;
            PositionCodeLevel = this.userDetails.userHighestPosition;
        }
        return {
            ids: ids,
            PositionCodeLevel: PositionCodeLevel,
        }
    }
    //#endregion
    
    //#region Product Categories
    private productDivisions : Array<any> = []
    private primaryCategories_  : Array<any> = []
    private secondaryCategories_ : Array<any> = []
    private products_ : Array<any> = []
    private variants_ : Array<any> = []
    private alternateCategory : Array<any> = []

    private selectedDiv : Array<any> = [];
    private selectedPC : Array<any> = [];
    private selectedSC : Array<any> = [];
    private selectedProduct : Array<any> = [];
    private selectedVariant : Array<any> = [];
    private selectedAC : Array<any> = [];

    get showCategoryFilters(): boolean{
        return this.filters.some(f => f == IndividualFilterType.ProductCategoryMultiSelect);
    }
    get primaryCategories(): Array<any>{
        return this.primaryCategories_.filter(pc => this.selectedDiv.some(pPC => pPC.id == pc.parentId));
    }
    get secondaryCategories() : Array<any>{
        return this.secondaryCategories_.filter(sc => this.selectedPC.some(sPC => sPC.id == sc.parentId));
    }
    get products() : Array<any>{
        return this.products_.filter(p => this.selectedSC.some(sC => sC.id == p.parentId));
    }
    get variants() : Array<any>{
        return this.variants_.filter(p => this.selectedProduct.some(sC => sC.id == p.parentId));
    }
    private divChange(pds : Array<any>){
        this.selectedDiv = pds;
        this.getRefAsAny("pcSelect").clickItem(this.primaryCategories);
    }

    private pcChange(pcs : Array<any>){
        this.selectedPC = pcs;
        this.getRefAsAny("scSelect").clickItem(this.secondaryCategories);
    }
    private scChange(scs : Array<any>){
        this.selectedSC = scs;
        this.getRefAsAny("productSelect").clickItem(this.products);
    }
    private productChange(pro : Array<any>)
    {
        console.log('products',pro)
        this.selectedProduct = pro;
        this.getRefAsAny("variantSelect").clickItem(this.variants);
    }
    private variantChange(vro : Array<any>)
    {
        this.selectedVariant = vro;
    }
    private acChange(acs : Array<any>)
    {
        this.selectedAC = acs;
    }
    private async loadProductCategoryFilters() : Promise<void> {
        let allPromises : Array<Promise<AxiosResponse<any>>> = [];
        allPromises.push(ProductService.getProductDivisions())
        allPromises.push(ProductService.getPrimaryCategories());
        allPromises.push(ProductService.getSecondaryCategories());
        allPromises.push(ProductService.getProducts());
        allPromises.push(ProductService.getVariants());
        allPromises.push(ProductService.getAlternateCategory());
        Promise.all(allPromises).then(allRepsonses => {
            this.productDivisions = allRepsonses[0].data;
            this.primaryCategories_  = allRepsonses[1].data;
            this.secondaryCategories_ = allRepsonses[2].data;
            this.products_ = allRepsonses[3].data;
            this.variants_ = allRepsonses[4].data;
            this.variants_ = this.variants_.filter(f=> f.name!= null);
            this.alternateCategory = allRepsonses[5].data;

            setTimeout(_ => {
                this.getRefAsAny("divSelect").clickItem(this.productDivisions);
                this.getRefAsAny("pcSelect").clickItem(this.primaryCategories);
                this.getRefAsAny("scSelect").clickItem(this.secondaryCategories);
                this.getRefAsAny("productSelect").clickItem(this.products);
                this.getRefAsAny("variantSelect").clickItem(this.variants);
                this.getRefAsAny("acSelect").clickItem(this.alternateCategory);
            }, 3000);
        }).catch(errors => {
            this.$emit('errorLoading', errors);
        });
    }
    private getLowestLevelProductFilter(): any {
        let ACName : Array<string> = [];
        let ids : Array<number> = [];
        let filterOn !: ProductHierarchy;
        if(this.selectedAC.length > 0 && this.selectedAC.length < this.alternateCategory.length){
            ACName = this.selectedAC.map(e=> e.name);
        }
        else{
            ACName = [];
        }
        if(this.selectedVariant.length> 0 && this.selectedVariant.length < this.variants.length){
            ids= this.selectedVariant.map(e=> e.id);
            filterOn = ProductHierarchy.Product;
        }
        else if(this.selectedProduct.length> 0 && this.selectedProduct.length < this.products.length){
            var h =  this.selectedProduct.map(e=> e.id);
            //ids=  this.variants_.filter(p => this.selectedProduct.some(sC => sC.id == p.parentId)).map(e=> e.id);
            ids = h
            filterOn = ProductHierarchy.Product;
        }
        else if(this.selectedSC.length > 0 && this.selectedSC.length < this.secondaryCategories.length){
           ids = this.selectedSC.map(e => e.id);
           filterOn = ProductHierarchy.SecondaryCategory;
        }
        else if(this.selectedPC.length > 0 && this.selectedPC.length < this.primaryCategories.length){
            ids = this.selectedPC.map(e => e.id);
            filterOn = ProductHierarchy.PrimaryCategory;
        }
        else if(this.selectedDiv.length > 0 && this.selectedDiv.length < this.productDivisions.length){
            ids = this.selectedDiv.map(e => e.id);
            filterOn = ProductHierarchy.Division;
        }
        else{
            ids = [];
            filterOn = ProductHierarchy.NoFilter;
        }
        return {
            ACName : ACName,
            ids: ids,
            filterOn: filterOn
        };
    }
    //#endregion

   private showDataForOptions = [{
        value:0,
        display:"All"

    },
    {
        value:1,
        display:"Only Products that were sold"
    },
    {
        value:2,
        display:"Only Productive Calls"
    }]
     
   private saleTypeVanSalesOptions = [{
        value:0,
        display:"All"

    },
    {
        value:1,
        display:"Pre-Sales"
    },
    {
        value:2,
        display:"Van Sales"
    }]
    
    private selectedShowDataFor = 0;
    private selectedSaleTypeVanSales = 0;
    

    get showOtherFilters():boolean{
      return this.filters.some(f => f == IndividualFilterType.PSODShowDataForFilter || f == IndividualFilterType.SaleTypeVanSale);
    }
    private loadFilters() : Array<Promise<void>>{
        let allPromises : Array<Promise<void>> = [];
        allPromises.push(this.loadEmployeeFilters());
        allPromises.push(this.loadPositionFilters());
        allPromises.push(this.loadProductCategoryFilters());
        return allPromises;
    }
    public getSelectFilters() {
        debugger
        let res : any = {};
        if(this.showEmployeeFilters){
            let users = this.getLowestRoleSelectedUserIds();
            res.userIds = users.ids.join(',');
            res.userRole = users.userRole;
        }
        if(this.showPositionFilters){
            let positions = this.getLowestLevelSelectedPositionIds();
            res.pcIds = positions.ids.join(',');
            res.PositionCodeLevel = positions.PositionCodeLevel;
        }
        if(this.showCategoryFilters){
            let productFilter = this.getLowestLevelProductFilter();
            res.productFilterIds = productFilter.ids;
            res.productFilterLevel = productFilter.filterOn;
            debugger;
            res.productAlternateCategory = productFilter.ACName;
        }
        if(this.showOtherFilters){
            res.showDataFor=this.selectedShowDataFor;
            res.saleTypeVanSales =this.selectedSaleTypeVanSales;
        }
        return res;
    }
}
