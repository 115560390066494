import client from './../apiService';
import {UserRole} from '@/interfaces/Enums'
class EmployeeService{
    getEmployeeBySearch(searchString: string){
        const url = `Employee/GetEmployeeBySearch?searchString=`+searchString;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getEmployeesOfRole(userRole: UserRole){
        const url = `Employee/GetAllEmployeesOfRole?userRole=`+userRole;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}

export default new EmployeeService();