import client from './../apiService';

class ProductService{
    getAllPC(){
        const url = `Product/GetAllPC`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getAllSC(){
        const url = `Product/GetAllSC`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getAllSCForPCIds(ids: Array<number>)
    {
        const url = `Product/GetAllSCForPC`;
        return client.post(url, ids,{
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getAllProduct(body:any){
        const url = 'Product/GetAllProduct';
        return client.post(url,body, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    getProductDivisions() {
        const url = `Product/GetProductDivisions`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getPrimaryCategories() {
        const url = `Product/GetPrimaryCategories`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getSecondaryCategories() {
        const url = `Product/GetSecondaryCategories`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    getProducts() {
        const url = `Product/GetProducts`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    async getVariants() {
        const url = `Product/GetVariants`;
        return await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    getAlternateCategory() {
        const url = `Product/GetAlternateCategory`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    // getAllProduct(pcIds: Array<number>, scIds: Array<number>){
    //     let url = 'Product/GetAllProduct';
    //     if(pcIds.length>0&&scIds.length>0)
    //     {
    //         url = url + '?pcIds='+pcIds+'&scIds='+scIds;
    //     }
    //     else if(pcIds.length>0)
    //     {
    //         url = url + '?pcIds='+pcIds;
    //     }
    //     else if(scIds.length>0)
    //     {
    //         url = url + '?scIds='+scIds;
    //     }

    //     return client.get(url, {
    //         headers: {
    //             'Content-Type': 'application/json'
    //         }
    //     });
    // }
}

export default new ProductService();