import client from './../apiService';

class CompanySettingService{
    getDistributorMapping(){
        const url = `Company/GetDistributorMapping`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getHierarchyNomenclature(){
        const url = `Company/GetHierarchyNomenclature`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    
    async companyUsesChainSKUMapping(){
        const url = `Company/CompanyUsesChainSKUMapping`;
        return await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async companyUsesImageRecognition(){
        const url = `Company/CompanyUsesImageRecognition`;
        return await client.get(url, {
            headers: {
             'Content-Type': 'application/json'
            }
        })
    }
    
    async CompanyUsesCustomerLevelTertiary(){
        const url = `Company/CompanyUsesCustomerLevelTertiary`;
        return await client.get(url, {
            headers: {
             'Content-Type': 'application/json'
            }
        })
    }
    async CompanyUsesTertiaryOffTakeManual(){
        const url = `Company/CompanyUsesTertiaryOffTakeManual`;
        return await client.get(url, {
            headers: {
             'Content-Type': 'application/json'
            }
        })
    }

    async getCompanyNomenclatures(){
        const url = `Company/GetCompanyNomenclature`;
        return await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
}

export default new CompanySettingService();