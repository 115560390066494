import {DateRangePreset,IndividualFilterType, UserRole, PositionLevel, DistributorGeographicalHierarchy, 
    GeographicalHierarchy, ProductHierarchy, GeographyLevel, EmployeeGeographicalHierarchy,DateFiltersType, 
    InputType} from './Enums'
export enum ReportType{
    Unknown = 0,
    ActivityDesign = 40,
    AttendanceReport = 100,
    OutletWiseSalesReport = 180,
    OutletWiseSalesReportEmpHierarchy = 181,
    OutletWiseSalesReportV4 = 182,
    DetailedSalesReport = 200,
    NoSalesAnalysisReport = 210,
    DeliveryOrderFlatDesign = 250,
    OutletWiseTarget = 270,
    OutletWiseTargetPC = 280,
    PeoplePerformanceReport = 290,
    SummarySheet = 300,
    SummarySheetUsingPivot = 305,
    OpeningClosingReport = 310,
    OpeningClosingReportV4 = 312,
    OpeningClosingReportDetailed = 315,
    NewAttendanceSummary = 440,
    NewAttendanceSummarySecondsheet = 450,
    AttendanceReportFromReportPerspective = 451,
    CategoryAnalysis = 460,
    CategoryAnalysisReport = 465,
    MonthOnMonthCategoryAnalysis = 470,
    CategoryAnalysisReportFromPerspective = 471,
    MonthOnMonthCategoryAnalysisFromPerspective = 472,
    FlatSales = 320,
    FlatSalesWithParams = 321,
    FlatSalesWithAllAtZSM = 322,
    FlatSalesFromReportPerspective = 323,
    FlatSalesFromReportPerspectiveCreatedInPartsMechanism = 324,
    FlateSalesGeographicalHierarchy = 325,
    FlatSalesDistFromSales = 326,
    FlatSalesLive = 327,
    NoSalesReasonReport = 328,
    FlatRetailerStockReport = 330,
    FlatSaleReturnReplacement = 340,
    FlatDistributorStock = 350,
    SecondaryOrderValidationReport = 360,
    SecondaryOrderValidationReportFromReportPerspective = 361,
    SecondaryOrderValidationReportWithParams = 351,
    EmployeePerformanceReport = 370,
    EmployeePerformanceReportFromReportsPerspective = 375,
    LVCR = 380,
    SummarySheetPDWiseFromPerspective = 389,
    SummarySheet_SC = 390,
    SummarySheetJockey = 391,
    SummarySheetJockeyFromPerspective = 392,
    SummarySheetFromPerspective = 393,
    SummarySheetSCWiseFromPerspective = 394,
    SummarySheetSCWiseFromPerspectiveUsingNewIds = 395,
    SummarySheetMT = 396,
    SummarySheetManagerWorkingFromPerspective = 397,
    SummarySheetMTSuperVisor = 398,
    PeoplePerformanceReportSC = 400,
    MOMEmpPerformance = 410,
    ModernTradeTeritiarySales = 480,
    ModernTradeStoreStock = 490,
    ModernTradePurchaseOrdedrs = 500,
    ModernTradeFillRate = 510,
    ASMRSMWorking = 530,
    NewOutletSale = 540,
    NewKRATargetReport = 560,
    EmployeeWiseTargetReport = 570,
    EmployeeWiseTargetReportOverAll = 575,
    DetailedSalesReportBook = 580,
    DistributorPerformanceOnBasis = 550,
    NewActivitySheet = 430,
    ProductSurveyResponseReport = 600,
    FlatSchemeSales = 610,
    FlatPrimaryOrderReport = 620,
    ConsolidatedSurveyReport = 630,
    FlatSurveyReport = 635,
    FlatSurveyReportDailySummary = 636,
    FlatSurveyReportFromV4 = 637,
    EmbeddedDailyEmail = 640,
    OutletDumpGeoHierarchy = 650,
    OutletDumpEmpHierarchy = 660,
    TourPlanSubmission = 670,
    PJPAdherence = 680,
    PJPAdherenceFromReportPerspective = 681,
    PJPAdherenceFromReportPerspectiveAllSeg = 682,
    FlatPaymentReport = 690,
    OCER = 700,
    OCERFromReport = 701,
    BCER = 710,
    FlatStarTVReport = 720,
    BrandWiseMTDReport = 730,
    FlatRetailerReturnReportDump = 740,
    FlatRetailerReturnReport = 745,
    TADAReport = 750,
    DeliveryOrderApparel = 760,
    FlatSalesDistFromSalesWithoutParams = 770,
    FlatWalkInOrderReport = 780,
    StockInwardReport = 790,
    StockTertiaryReport = 800,
    RouteChangeRequestReport = 810,
    ModernTradeClosingStockReport = 820,
    OutletCreationRequestReport = 830,
    MasterDataDumpOutletCountReport = 840,
    ImageRecognitionReport = 850,
    SummaryReportVersion2 = 860,
    ImageAuditingReportTotalScore = 870,
    MustSellReportFromReportPerspective = 880,
    KRAAdherenceReportFromReportPerspective = 890,
    NewOutletDetailedReport = 900,
    EmployeeProductivityReportFromPerspective = 910,
    FocusProductReportFromV4 = 917,
    TourPlanAdherenceReportFromPerspective = 920,
    EOCERFromPerspective = 930,
    OutletReachReportV4 = 935,
    BVCRFromPerspective = 940,
    DailyDistributorSalesReport = 945,
    TimeLineReport = 950,
    DailyLocationReportV4 = 960,
    TransactionDumpReport = 970,
    OpeningClosingStockReportV4 = 980,
    RoutePlanReport = 990,
    Other = 1000,
    VisitDumpReportFromPerspective = 1010,
    EmployeeWiseTargetVsAch = 1015,
    MTCalculatedClosingReport = 1020,
    MTEmployeeWiseTargetVsAch = 1030,
    MTAttendanceReport = 1035,
    DailyStatsReportLiveV4 = 1040,
    DeadOutletReport = 1050,
    EmployeeDistributorPerformanceReport = 1060,
    LivePrimaryOrderReport = 1065,
    PaymentCollectionReport = 1070,
    BattleGroundReport = 1075,
    MTSummaryReport = 1080,
    MTPerformanceAnalysisReport = 1085,
    FlatSchemePerformanceReport = 1095,
    MTISRSummaryReport = 1090,
    OutletTargetReportV4 = 1100,
    NewOutletDetailedHybridReport = 1105,
    VanStockReport = 1110,
    JockeyRetailerSalesDispatch = 4001,
    FlexibleReport = 4004,
    MonthlyDistributerStockReport = 5004,
    DateWiseDistributerStockReport = 5017,
    PrimarySalesReport_FromMaster = 5005,
    PrimaryOrdersReport = 5006,
    UserLoginActivity = 5007,
    FlatSalesFromReportPerspectiveIsProductive = 5008,
    LDMSFlatSales = 5001,
    LDMSClaimReports = 5002,
    LDMSInventory = 5003,
    InvoiceReport = 5009,
    OutletCategoryWiseSalesReport = 5010,
    InvoiceReportForAccountManager = 5015,
    UserJourneyLocationReport = 5016,
    L3MEmpAnalysis = 5020,
    TADAExpenseDump = 5025
}
export interface OtherReportPreferences{
    displayName: string,
    name: string,
    inputType: InputType,
    value: string,
}

export interface ReportDetails{
    reportId: number,
    customReportId: number,
    maxColumnsSelectable: number,
    name: string,
    canCustomize : boolean,
    dateFilterType : DateFiltersType,
    datePresets : Array<string>,
    maxDaysAllowed : number,
    filters : Array<IndividualFilterType>,
    userDetails : UserDetails,
    columns: Array<any>,
    categories: Array<string>,
    displayCategories: Array<string>,
    otherPreferences: Array<OtherReportPreferences>,
}

export interface ReportDetail {
    Name:                                    string;
    FilterType:                              IndividualFilterType;
    SubscriptionKey:                         string;
    IsDownloadable:                          boolean;
    IsLoadTesting:                           boolean;
    IsRequestable:                           boolean;
    IsQueueable:                             boolean;
    IsQueueableDownload:                     boolean;
    IsUsingPositionCode:                     boolean;
    GeographicalHierarchyDetails:            GeographyHierarchyDetails;
    EmployeeGeographicalHierarchyDetails:    EmpGeographyHierarchyDetails;
    UserHierarchyDetails:                    UserHierarchyDetails;
    ProductHieracyDetails:                   ProductHierarchyDetails;
    DistributorGeographicalHierarchyDetails: DistributorGeographyHierarchyDetails;
    EnumForReportAssembly:                   ReportType;
    DateFiltersType:                         DateFiltersType;
    MaxMonthsForDownload:                    number;
    MaxDaysForDownload:                      number;
    MaxDaysForRequest:                       number;
    MaxMonthsForRequest:                     number;
    SelectedDate:                            Date;
    SelectedDateTimeStamp:                   number;
    AjaxRequestDownloadUrl:                  string;
    CompanyId:                               number | null;
    TillLastDay:                             boolean;
    IsUsesJourneyCalendar:                   boolean;
    UserId:                                  number;
    UserRole:                                UserRole;
    IsWithOutFilters:                        boolean;
    IsUsingNewIds:                           boolean;
    ESMNomenClature:                         string | null;
    ButtonForShowAllColumns:                 boolean;
    Nomenclature:                            Map<string, string> | null;
}

export interface ProductHierarchyDetails {
    IncludeAll:              boolean;
    PageUsesHighestFilter:   ProductHierarchy;
    PageUsesLowestFilter:    ProductHierarchy;
    IncludeAllFromHierarchy: ProductHierarchy;
}

export interface GeographyHierarchyDetails {
    IncludeAll:              boolean;
    PageUsesHighestFilter:   GeographicalHierarchy;
    PageUsesLowestFilter:    GeographicalHierarchy;
    IncludeAllFromHierarchy: GeographicalHierarchy;
    CompanyHighestGeoLevel?: GeographyLevel;
}

export interface EmpGeographyHierarchyDetails {
    IncludeAll:              boolean;
    PageUsesHighestFilter:   EmployeeGeographicalHierarchy;
    PageUsesLowestFilter:    EmployeeGeographicalHierarchy;
    IncludeAllFromHierarchy: EmployeeGeographicalHierarchy;
}

export interface DistributorGeographyHierarchyDetails {
    IncludeAll:              boolean;
    PageUsesHighestFilter:   DistributorGeographicalHierarchy;
    PageUsesLowestFilter:    DistributorGeographicalHierarchy;
    IncludeAllFromHierarchy: DistributorGeographicalHierarchy;
}

export interface UserHierarchyDetails {
    UserId:                              number;
    UserRole:                            UserRole;
    CompanyUsesHighestRole:              UserRole;
    PageUsesLeastRole:                   UserRole;
    IncludeAll:                          boolean;
    IncludeAllFromHierarchy:             UserRole;
    ForFieldUser:                        boolean;
    IncludeDeactiveESM:                  boolean;
    IsISROnly:                           boolean;
    CompanyUsesHighestPositionCodeLevel: PositionLevel;
    IncludeAllFromLevel:                 PositionLevel;
    UserHighestPositonCodeLevel:         PositionLevel;
    UserHighestPositonCodeIds:           Array<number>;
    UserPositionCodes:                   Map<number, PositionLevel>;
    ShowBothFilters:                     boolean;
}

export interface UserDetails{
    id: number,
    newId: number,
    highestRole: UserRole,
    userRole: UserRole,
    highestPosition: PositionLevel,
    userHighestPosition: PositionLevel,
    positionIds: Array<number>,
    usesPositionCodes: boolean
}

const datePresets : any ={
    "MTD": {
        from: () => (new Date((new Date()).setDate(1))).toISOString().split("T")[0],
        to: () => (new Date(new Date().valueOf() - 24 * 60 * 60 * 1000)).toISOString().split("T")[0]
    },
    "Last 7 Days": {
        from: () => (new Date((new Date()).valueOf() - 1000 * 60 * 60 * 24 * 7)).toISOString().split("T")[0],
        to: () => (new Date(new Date().valueOf() - 24 * 60 * 60 * 1000)).toISOString().split("T")[0]
    }
}
export {datePresets};