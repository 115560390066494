




















































import {Vue, Prop, Emit, Component} from 'vue-property-decorator'

@Component
export default class DropDown extends Vue{
    @Prop() readonly items!:Array<any>
    @Prop() readonly placeholder!:string
    @Prop() readonly label!:string

    @Emit('change')
    change(selectedItems: Array<any>){
        this.selectedItems = selectedItems.filter(i => typeof(i) == 'object');
        return this.selectedItems;
    }

    private selectedItems: Array<any> = []
    private isFocused: boolean = false;
    private searchInput : string = "";
    private menuProps : object = {
        closeOnContentClick: false,
        closeOnClick: false,
    }

    public get displayItems() : Array<any> {
        if(this.isFocused && (this.searchInput == null || this.searchInput.length < 1))
            return [...this.selectedItems];
        return this.items;
    }

    private focused(){
        this.clickItem([]);
        this.isFocused = true;
    }

    private blurred(){
        this.isFocused = false;
        if(this.selectedItems.length == 0)
            this.clickItem(this.displayItems);
    }

    public checkBoxStyle(item: any, list: Array<any>){
        if(list.find(i => i.id == item.id)){
            return "color: var(----primaryFABlue);";
        }else{
            return "color: var(--highlightGray);";
        }
    }
    public checkBoxIcon(item: any, list: Array<any>){
        if(list.find(i => i.id == item.id)){
            return "mdi-checkbox-marked";
        }else{
            return "mdi-checkbox-blank";
        }
    }
    public clickItem(items: Array<any>){
        this.selectedItems = items;
        this.$emit('change',this.selectedItems)
    }
    public get showSelected(){
        console.log('displayItems');
      let joinedItems = this.selectedItems.map(i => i.name).join(", ");
           return `(${this.selectedItems.length})${joinedItems}`.substr(0,23) + (joinedItems.length > 22 ? '..' : '');
    }
}
