































































import Vue from "vue";
import {Prop, Component, Watch} from 'vue-property-decorator'

import {DateFiltersType, DateRangePreset} from '@/interfaces/Enums'
import {datePresets} from '@/interfaces/reports'

@Component({
    components:{
    }
})
export default class DateFilter extends Vue {
    @Prop({default: DateFiltersType.None}) type!: DateFiltersType;
    @Prop({default: []}) presets!: Array<string>;
    @Prop({default: 31}) maxDays!: number;

    private dateFromMenu: boolean = false;
    private dateToMenu: boolean = false;
    private from: string = (new Date(new Date().valueOf() - 24 * 60 * 60 * 1000)).toISOString().split('T')[0];
    private to: string = (new Date(new Date().valueOf() - 24 * 60 * 60 * 1000)).toISOString().split('T')[0];
    private selectedPreset: number | null = null;

    get isNotNoneType() : boolean{
        return this.type != DateFiltersType.None;
    }

    get isDateRangeType() : boolean{
        return this.type == DateFiltersType.DateRange;
    }

    private allowedFromDates(dateString: string) : boolean {
        let diff = this.differenceInDays(dateString, new Date());
        return diff > 0;
    }
    private getWeek(value: any) {
        let date = new Date(value);
        return date.toString().split(' ')[0];
    }
    private allowedToDates(dateString: string) : boolean {
        let diff = this.differenceInDays(this.from, dateString)
        let res = diff >= 0 && diff < this.maxDays; //Condtion for to >= from and to-from <= maxdays
        let todayKey = this.getDateKey(new Date(new Date().valueOf() - 24 * 60 * 60 * 1000));
        let dateKey = this.getDateKey(dateString);
        res = res && todayKey >= dateKey ; //check for Today
        return res;
    }
    get formattedFrom() : string{
        let date = (new Date(this.from)).toUTCString().split(' ');
        return `${date[1]}-${date[2]}-${date[3]}`;
    }
    get formattedTo() : string{
        let date = (new Date(this.to)).toUTCString().split(' ');
        return `${date[1]}-${date[2]}-${date[3]}`;
    }
    private changeTo() {
        this.dateToMenu = false;
        this.selectedPreset = null;
    }

    private changeFrom() {
        this.dateFromMenu = false;
        this.selectedPreset = null;
        let diff = this.differenceInDays(this.from, this.to);
        if(diff > this.maxDays){
            this.to = new Date((new Date(this.from)).valueOf() + 1000*60*60*24*(this.maxDays-1)).toISOString().split('T')[0]; 
        }
        else if(diff < 0){
            this.to = this.from;
        }
    }

    @Watch('selectedPreset')
    selectPreset(index: number | null){
        if(typeof index == 'number'){
            let preset = this.presets[index]
            this.from = datePresets[preset].from();
            this.to = datePresets[preset].to();
        }
    }

    private differenceInDays(from : Date | string, to: Date | string) : number{
        let toDate = new Date(to);
        let fromDate = new Date(from);
        let diff = toDate.valueOf() - fromDate.valueOf();
        return Math.floor(diff/(1000 * 60 * 60 * 24)) ;
    }
    private getDateKey(date: Date | string) : number {
        if(typeof date == "string"){
            return parseInt(date.split('-').join(""));
        }
        else{
            return parseInt(date.toISOString().split('T')[0].split('-').join(""));
        }
    }

    public getDateFilters() : any {
        switch (this.type) {
            case DateFiltersType.DateRange:
                return{
                    FromDateTimeStamp: this.getDateKey(this.from),
                    ToDateTimeStamp: this.getDateKey(this.to)
                };
            default:
                return {}
        }
    }
}
